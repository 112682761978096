import React from "react"
import Layout from "../components/Layout/Layout"
import { Link } from "gatsby"
import styled from "styled-components"

const Container = styled.div`
  .error-page {
    padding: 6vw 20vw;
    background-color: #f7f7f7;
  }
  .error-container h1 {
    font-size: 4vw;
    font-weight: 900;
    margin: 2vw 0;
  }

  .error-container p {
    font-size: 2vw;
    font-weight: bold;
    margin: 4vw 0;
  }

  .back-btn {
    box-shadow: 0px 0px 4px darkgrey;
    padding: 1vw 3vw;
    vertical-align: middle;
    display: block;
    width: 20vw;
    text-align: center;
    font-size: 1.5vw;
    color: black;
  }
`

const Error = () => {
  return (
    <Container>
      <Layout>
        <main className="error-page">
          <div className="error-container">
            <h1>잘못된 페이지 입니다.</h1>
            <p>뒤로 돌아가거나 다른 메뉴를 클릭해주세요.</p>
            <Link to="/" className="back-btn">
              뒤로 돌아가기
            </Link>
          </div>
        </main>
      </Layout>
    </Container>
  )
}

export default Error
